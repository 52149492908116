import css from "./scss/style.scss";
import _ from "@fortawesome/fontawesome-free/js/all"

import "./js/external-link";
import { initSticky } from "./js/menu-sticky";
import { initBurger } from "./js/burger";
import { initGallery } from "./js/gallery";
import { initSlide } from "./js/slider";
import { initCopyClipBoard } from "./js/copy-clipboard";
import { initCollapsible } from "./js/collapsible";
import { initModalForm } from "./js/modal-form";
import { subMenu } from "./js/menu-dropdown";
import { initVideos, resizeVideos } from "./js/video";
import { initForms, resizeForms } from "./js/form";
import { initDomsearch } from "./js/domsearch";
import { tabsWithContent } from "./js/content-tabs";

function initDelete() {
    document.querySelectorAll(".notification .delete").forEach(
        (closeButton) => {
            const notification = closeButton.parentNode;
            if (sessionStorage.getItem("notification") == "closed") {
                notification.parentNode.removeChild(notification);
            } else {
                notification.classList.remove("is-hidden");
                closeButton.addEventListener("click", () => {
                    notification.parentNode.removeChild(notification);
                    sessionStorage.setItem("notification", "closed");
                });
            }
        }
    );
}

function initSite() {
    initSlide(".slider");
    initGallery(".gallery");
    initBurger(".navbar-burger");
    initSticky();
    initCopyClipBoard();
    initCollapsible();
    initModalForm();
    subMenu();
    initDelete();
    initForms();
    resizeForms();
    initVideos();
    resizeVideos();
    initDomsearch();
    tabsWithContent();
}
// Resize the iframes when the window is resized
window.addEventListener("resize", resizeVideos);
document.addEventListener("DOMContentLoaded", initSite,);
