export function subMenu() {
    const width = document.body.offsetWidth;
    const menuDropdown = document.querySelectorAll('.has-dropdown');
    const menuBack = document.querySelectorAll('.navbar-back');
    const menuItem = document.querySelectorAll('.navbar-item');

    if (width <= 768) {

        [...menuDropdown].forEach(el => {
			let link = el.querySelector('.navbar-link');

            if(link !== null) {
                link.addEventListener('click', () => {
                    el.classList.add('show-dropdown');
                })
            }
	    });

        [...menuBack].forEach(el => {
            el.addEventListener('click', () => {
                el.parentElement.parentElement.parentElement.classList.remove('show-dropdown');
            })
        });
    } else {

        [...menuItem].forEach(el => {
            el.addEventListener('mouseenter', (ev) => {
                ev.preventDefault();
                ev.stopPropagation();
                ev.stopImmediatePropagation();
                setTimeout(function(){
                    el.classList.add('hover-dropdown');
                }, 500);
            })
            el.addEventListener('mouseleave', (ev) => {
                ev.preventDefault();
                ev.stopPropagation();
                ev.stopImmediatePropagation();
                setTimeout(function(){
                    el.classList.remove('hover-dropdown');
                }, 500);
            })
        });
    }
}