export function initCollapsible (blockSelector=".collapsible") {
    const block = document.querySelectorAll(blockSelector);
    
    block.forEach((element) => {
        let icon = 'is-up';
        let blockH = element.parentElement;
        let target = blockH.nextElementSibling;

        if (!(blockH.classList.contains("is-up"))) {
            target.classList.add("is-closed");
        } else {
            icon = 'is-down';
        }

        blockH.classList.add(icon);
        element.addEventListener("click", (event) => {
            event.preventDefault();
            blockH.classList.toggle("is-up");
            target.classList.toggle("is-closed");
        });
    });
}