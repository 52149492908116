export const tabsWithContent = () => {
  const tabs = document.querySelectorAll(".tabs li");
  const tabsContent = document.querySelectorAll(".tabcontent");

  const deactivateAllTabs = () => {
    tabs.forEach((tab) => {
      tab.classList.remove("is-active");
    });
  };

  const hideTabsContent = () => {
    tabsContent.forEach((tabContent) => {
      tabContent.classList.remove("is-active");
    });
  };

  const activateTabsContent = (tab) => {
    tabsContent[getIndex(tab)].classList.add("is-active");
  };

  const getIndex = (el) => {
    return [...el.parentElement.children].indexOf(el);
  };

  tabs.forEach((tab) => {
    tab.addEventListener("click", (event) => {
      event.stopPropagation();

      deactivateAllTabs();
      hideTabsContent();
      tab.classList.add("is-active");
      activateTabsContent(tab);
    });
  });
  if (tabs.length) {
    tabs[0].click();
  }
  
};
